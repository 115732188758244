import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { RouteModules } from '../../enums/route-modules.enum';
import { JwtLegFiClaims } from '../../services/auth/jwt-legfi-claims.model';
import { GrowlerService } from '../../services/growler.service';

@Injectable()
export class PermissionsGuard implements CanActivate
{
    constructor(
            private _router: Router,
            private _growler: GrowlerService,
    ) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();

        if (jwt === null) {
            const loginView = ['/auth/login'];
            loginView.push(<any>{uri: encodeURIComponent(state.url)});
            this._router.navigate(loginView);
        }

        // no module for permissions specified, all users pass
        if (!next.data.module) {
            return true;
        }

        if (next.data.hasOwnProperty('feature')) {
            if (!LegFiJwtService.featureEnabled(next.data.feature)) {
                this._growler.oops('You are not authorized to view the requested page');
                // redirect if no checks pass
                this._router.navigate(['/']);
                return;
            }
        }

        if (next.data.module === RouteModules.SUPER_ADMIN) {
            if (jwt.superUser) {
                return true;
            }
        }

        if (next.data.module === RouteModules.ORG_ADMIN) {
            if (jwt.admin || jwt.superUser) {
                return true;
            }
        }

        const requireWrite = next.data.hasOwnProperty('write') && next.data.write === true;
        if (LegFiJwtService.doesUserHaveModulePermission(next.data.module, requireWrite)) {
            return true;
        }


        this._growler.oops('You are not authorized to view the requested page');
        // redirect if no checks pass
        this._router.navigate(['/']);
    }
}
