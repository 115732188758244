import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { HeaderSearchComponent } from './header-search.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatListModule } from '@angular/material/list';
import { MatInputModule } from '@angular/material/input';

@NgModule({
    imports: [
        SharedModule,
        MatFormFieldModule,
        MatIconModule,
        MatAutocompleteModule,
        MatProgressSpinnerModule,
        MatListModule,
        MatInputModule,
    ],
    declarations: [HeaderSearchComponent],
    providers: [],
    exports: [
        HeaderSearchComponent,
    ],
})
export class HeaderSearchModule
{
}
