import moment, { Moment } from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { Unit } from './unit';
import { User } from './user';
import { Address } from './address';
import { Tag } from 'app/models/entities/tag';
import { ViolationStatementComment } from 'app/models/entities/violation-statement-comment';
import { UploadedFile } from 'app/models/entities/uploaded-file';

export class ViolationStatement
{
    id: number;
    organizationId: number;
    userId: number;
    unitId: number;
    title: string;
    message: string;
    header: string;
    footer: string;
    flatMessage: string;
    status: string;
    violationStatusId: number;
    reportedAt: Moment;
    resolutionTime: Moment;
    completedTime: Moment;
    createdAt: Moment;
    updatedAt: Moment;
    deletedAt: Moment;

    comments: ViolationStatementComment[] = [];
    attachments: UploadedFile[] = [];
    unit: Unit;
    user: User;
    tags: Tag[] = [];

    matchesTags: boolean = false;

    constructor(violation: any, unit: any = null) {
        const timezone = LegFiJwtService.getTimezone();

        this.id = violation.id;
        this.organizationId = violation.organizationId;
        this.userId = violation.userId;
        this.unitId = violation.unitId;
        this.title = violation.title;
        this.header = violation.header;
        this.footer = violation.footer;
        this.status = violation.status;

        if (violation.reportedAt) {
            this.reportedAt = moment.utc(violation.reportedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }

        if (violation.createdAt) {
            this.createdAt = moment.utc(violation.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);

            // adding for now for violations missing reported date (just in case)
            if (!this.reportedAt) {
                this.reportedAt = this.createdAt;
            }
        }

        if (violation.completedTime) {
            this.completedTime = moment.utc(violation.completedTime, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }

        if (violation.message) {
            this.message = violation.message;

            this.flatMessage = violation.message.replace(
                    /(<|&lt;)br\s*\/*(>|&gt;)/g,
                    ' ',
            );
        }

        if (violation.resolutionTime) {
            this.resolutionTime = moment
                    .utc(violation.resolutionTime, 'YYYY-MM-DD hh:mm:ss')
                    .tz(timezone);
        }


        if (violation.updatedAt) {
            this.updatedAt = moment
                    .utc(violation.updatedAt, 'YYYY-MM-DD hh:mm:ss')
                    .tz(timezone);
        }
        if (violation.deletedAt) {
            this.deletedAt = moment
                    .utc(violation.deletedAt, 'YYYY-MM-DD hh:mm:ss')
                    .tz(timezone);
        }

        if (violation.attachments) {
            this.attachments = violation.attachments.map(attachment => {
                return new UploadedFile(attachment);
            });
        }
        if (unit || violation.unit) {
            this.unit = new Unit(unit || violation.unit);
        }
        if (violation.user) {
            this.user = new User(violation.user);
        }
        if (violation.comments) {
            this.comments = violation.comments.map(comment => {
                return new ViolationStatementComment(comment);
            });
        }
        if (violation.tags) {
            this.tags = violation.tags.map(tag => {
                return new Tag(tag);
            });
        }
    }
}

export class ViolationExportListItem
{
    unit: string;
    title: string;
    message: string;
    reported: string;
    created: string;
    resolveBy: string;
    completed: string;
    status: string;
    address: string;
    city: string;
    state: string;
    zip: string;
    comments: string;
    tags: string;

    constructor(request: ViolationStatement) {
        this.unit = request.unit.title || '';
        this.title = request.title || '';
        this.message = request.message.replace(/(<|&lt;)br\s*\/*(>|&gt;)/g, ' ') || '';
        this.reported = request.reportedAt.format('l');
        this.created = request.createdAt.format('l');
        this.resolveBy = request.resolutionTime ? request.resolutionTime.format('l') : '';
        this.completed = request.completedTime ? request.completedTime.format('l') : '';
        this.status = request.status;
        this.comments = request.comments.length === 0 ? '' : request.comments.map(c => {
            return c.user.name + ': ' + c.message;
        }).join(',');
        this.tags = request.tags.length === 0 ? '' : request.tags.map(tag => {
            return tag.tag;
        }).join(',');

        const a = new Address(request.unit.address);
        this.address = a.line1;
        this.city = a.city;
        this.state = a.region;
        this.zip = a.postalCode;
    }
}
