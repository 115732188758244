import { Injectable } from '@angular/core';
import { Tag } from 'app/models/entities/tag';
import { of, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApplicationHttpClient } from '../../components/shared/http/application-http-client';
import { TagsComponent } from '../../components/shared/tags/tags.component';
import { TagsDialogInput, TagsDialogOutput } from '../../components/shared/tags/tags-dialog/tags-dialog.component';
import { TagsService } from './tags.service';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogSizes } from '../../enums/mat-dialog-sizes.enum';

export interface TagTypeSubjects {
    [key: string]: ReplaySubject<Tag[]>;
}

/**
 * Current mapping from MODEL_CLASS_MAPPING in core
 */
export type TaggableTypes = 'Unit'|'Owner'|'Member'|'Submission'|'Violation';

/**
 * Supporting several uses of tags/chips in ui
 */
export type TaggableMode = 'Filter'|'Editor'|'Table';

@Injectable({
    providedIn: 'root'
})
export class TagsDataService {
    private replays$: TagTypeSubjects = {
        unit: new ReplaySubject<Tag[]>(1),
        member: new ReplaySubject<Tag[]>(1),
        memberArchived: new ReplaySubject<Tag[]>(1),
        submission: new ReplaySubject<Tag[]>(1),
        violation: new ReplaySubject<Tag[]>(1),
    };

    constructor(private _http: ApplicationHttpClient,
                private _tagsService: TagsService) {
    }

    getOrganizationTagsByType(type: TaggableTypes, onlyTrashed = false) {
        return this._tagsService.getTagsForType(type, [], onlyTrashed).pipe(map((tags: Tag[]) => {
            // Stripping out ids for shared tags in org
            tags = tags.map((t) => {
                delete t.id;
                return t;
            });

            switch(type) {
                case 'Unit':
                    this.replays$.unit.next(tags);
                    break;
                    // Owner and Member share the same tags in the ui
                case 'Owner':
                case 'Member':
                    if (onlyTrashed) {
                        this.replays$.memberArchived.next(tags);
                    } else {
                        this.replays$.member.next(tags);
                    }
                    break;
                case 'Submission':
                    this.replays$.submission.next(tags);
                    break;
                case 'Violation':
                    this.replays$.violation.next(tags);
                    break;
            }

            return tags;
        }));
    }

    getTagsByTaggableId(type: TaggableTypes, ids: number[] = [], onlyTrashed = false) {
        return this._tagsService.getTagsForType(type, ids, onlyTrashed).pipe(map((tags: Tag[]) => {
            return tags;
        }));
    }

    /** Helper method to get replay subject in as observable to subscribe to across components */
    getObservableByType(taggableType?: TaggableTypes, hasArchived = false) {
        switch(taggableType) {
            case 'Unit':
                return this.replays$.unit.asObservable();
                // owner and member share the same tags in the ui
            case 'Owner':
            case 'Member':
                return (hasArchived ? this.replays$.memberArchived : this.replays$.member).asObservable();
            case 'Submission':
                return this.replays$.submission.asObservable();
            case 'Violation':
                return this.replays$.violation.asObservable();
            default:
                // taggable type not supported!
                console.warn('Tag type not supported.');
                return of([]);
        }
    }

    /** Helper method to get placeholder text for tag ui based on type of tags (filter, read-only and unit, violations, etc.) **/
    getPlaceholderTextByModeAndType(modelType?: TaggableTypes, mode: TaggableMode = 'Filter') {
        return modelType ? modelType + ' Tags…' : 'Tags…';
    }

    /** Helper method to get empty placeholder text for tag ui based on type of tags (filter, read-only and unit, violations, etc.) **/
    getEmptyPlaceholderTextByModeAndType(modelType?: TaggableTypes, mode: TaggableMode = 'Filter') {
        const type = modelType ? modelType : '';
        let emptyPlaceholderText: string;
        switch (mode) {
            case 'Filter':
                emptyPlaceholderText = 'Filter by ' + type;
                break;
            default:
                emptyPlaceholderText = 'Add ' + type;
        }

        emptyPlaceholderText += ' tags…';
        return emptyPlaceholderText;
    }

    /** Helper method to get details needed by view all dialog for multiselect-drawer */
    getViewAllDialogDetails(type: TaggableTypes, components: TagsComponent[], taggableIds: number[]): TagsDialogInput {
        // get collection from all multiple, nested components
        const collection = components.filter((component) => taggableIds.indexOf(component.taggableId) > -1)
                .map((component) => component.items);

        if (collection.length === 0) {
            // no collection to reference, so cannot set up dialog for multiple items
            console.error('unable to set collection for tags');
            return;
        }

        // flatten all possible tags from collection
        let items: Tag[] = [];
        for (const tags of collection) {
            items = [
                ...items,
                ...tags,
            ];
        }

        // now get only tags shared across components
        const sharedItems = collection.reduce((a, b) => a.filter((c) => b.find((t) => t.tag === c.tag) !== undefined));
        return {
            items,
            sharedItems,
            obs: this.getObservableByType(type),
            taggableType: type,
            taggableIds,
            placeholder: this.getPlaceholderTextByModeAndType(type, 'Editor'),
            secondaryPlaceholder: this.getEmptyPlaceholderTextByModeAndType(type, 'Editor'),
            readonly: false,
            hasArchived: false,
        };
    }
}
