import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { TagsComponent } from './tags.component';
import { TagInputModule } from 'ngx-chips';
import { MatRadioModule } from '@angular/material/radio';
import { MatMenuModule } from '@angular/material/menu';
import { TagsDialogModule } from './tags-dialog/tags-dialog.module';
import { AdjustColorModule } from '../../../directives/adjust-color/adjust-color.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
    declarations: [
        TagsComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatRadioModule,
        MatMenuModule,
        TagInputModule,
        TagsDialogModule,
        AdjustColorModule,
        MatIconModule,
        MatProgressSpinnerModule,
    ],
    exports: [
        TagsComponent
    ],
    providers: [],
})
export class TagsModule { }
