import { Injectable } from '@angular/core';
import { ApplicationHttpClient } from '../../components/shared/http/application-http-client';
import { JwtLegFiClaims } from '../auth/jwt-legfi-claims.model';
import { LegFiJwtService } from '../auth/legfi-jwt.service';
import { Routes } from '../../config/routes';
import { map } from 'rxjs/operators';
import { PropertyManagementCompany, PropertyManagementCompanyResponse } from '../../models/entities/property-management-company';
import { OrganizationResponse } from '../../models/entities/organization';
import { PropertyManagementCompanyDataService } from './property-manager-company-data.service';
import { FormResponse } from '../../models/entities/forms/form';
import { FormSubmissionResponse } from '../../models/entities/forms/form-submission';
import { Observable } from 'rxjs';
import { FormSubmissionStatusRollup } from '../../models/entities/forms/form-submission-status';
import { TableFilters } from '../../models/entities/table-filters';

@Injectable({
    providedIn: 'root',
})
export class PropertyManagementCompanyService {
    constructor(
            private _http: ApplicationHttpClient,
            private _data: PropertyManagementCompanyDataService,
    ) {
    }

    public getPropertyManagementCompanies(
        search: string = '',
        sortColumn: string = 'name',
        sortDirection: string = 'asc',
        pageIndex: number = 1,
        perPage: number = 100,
        filters: { [key: string]: any } = {},
    ){
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const query = this._http.queryStringFromObject({
            'page': pageIndex,
            'search': search,
            'column': sortColumn,
            'direction': sortDirection,
            'per_page': perPage,
            ...filters
        });
        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.PropertyManagementCompanies + query,
        );

        return this._http.get(url).pipe(map((res) => {
            return new PropertyManagementCompanyResponse(res);
        }));
    }

    public getPropertyManagementCompany(pmcId: number) {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(Routes.LegFiCore.PropertyManagementCompany(pmcId));
        return this._http.get(url).pipe(map((res: any) => new PropertyManagementCompany(res)));
    }

    public getPropertyManagementOrganizations(
            search: string = '',
            sortColumn: string = 'name',
            sortDirection: string = 'asc',
            pageIndex: number = 1,
            perPage: number = 100,
            filters: { [key: string]: any } = {},
    ){
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const query = this._http.queryStringFromObject({
            'page': pageIndex,
            'search': search,
            'column': sortColumn,
            'direction': sortDirection,
            'per_page': perPage,
            ...filters
        });
        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.PropertyManagementOrganizations(this._data.pmcId) + query,
        );

        return this._http.get(url).pipe(map((res) => {
            return new OrganizationResponse(res);
        }));
    }

    public getPropertyManagementRequestForms(
            search: string = '',
            sortColumn: string = 'name',
            sortDirection: string = 'asc',
            pageIndex: number = 1,
            perPage: number = 100,
            filters: { [key: string]: any } = {},
    ){
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const query = this._http.queryStringFromObject({
            'page': pageIndex,
            'search': search,
            'column': sortColumn,
            'direction': sortDirection,
            'per_page': perPage,
            ...filters
        });
        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.PropertyManagementRequestForms(this._data.pmcId) + query,
        );

        return this._http.get(url).pipe(map((res) => {
            return new FormResponse(res);
        }));
    }

    public getPropertyManagementRequestFormSubmissions(
            search: string = '',
            sortColumn: string = 'name',
            sortDirection: string = 'asc',
            pageIndex: number = 1,
            perPage: number = 100,
            filters: TableFilters = {},
    ){
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const query = this._http.queryStringFromObject({
            'page': pageIndex,
            'search': search,
            'column': sortColumn,
            'direction': sortDirection,
            'per_page': perPage,
            ...filters
        });
        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.PropertyManagementRequestFormSubmissions(this._data.pmcId) + query,
        );

        return this._http.get(url).pipe(map((res) => {
            return new FormSubmissionResponse(res);
        }));
    }

    public getPropertyManagementRequestFormSubmissionStatuses(
            filters: { [key: string]: any } = {},
    ): Observable<FormSubmissionStatusRollup> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const query = this._http.queryStringFromObject(filters);
        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.PropertyManagementRequestFormSubmissionStatuses(this._data.pmcId) + query,
        );

        return this._http.get(url).pipe(map((res: { data: Object[]; counts: Object[]; }) => {
            return new FormSubmissionStatusRollup(res);
        }));
    }
}
