import { NgModule } from '@angular/core';

import { WelcomeModalComponent } from './welcome-modal.component';
import { ModalModule } from '../../../shared/modal/modal.module';
import { UserSettingsService } from '../../../../services/members/user-settings.service';
import { SharedModule } from '../../../shared/shared.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { IfOnDomModule } from '../../../../directives/if-on-dom.module';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule,
        IfOnDomModule,
        MatButtonModule,
        ModalModule,
        SharedModule,
    ],
    exports: [WelcomeModalComponent],
    declarations: [WelcomeModalComponent],
    providers: [
        UserSettingsService,
    ],
})
export class WelcomeModalComponentModule
{
}
