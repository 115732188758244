import { NgModule } from '@angular/core';
import { AuditHistoryComponent } from './audit-history.component';
import { SharedModule } from '../../../shared/shared.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { AdminService } from '../../../../services/admin/admin.service';
import { LegfiDatePipeModule } from '../../../../pipes/format/legfi-date.module';
import { LegfiJsonPipeModule } from '../../../../pipes/format/legfi-json.module';
import { LegfiJsonPipe } from '../../../../pipes/format/legfi-json.pipe';
import { LegfiDatePipe } from '../../../../pipes/format/legfi-date.pipe';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTableModule } from '@angular/material/table';
import { ButtonModule } from '../../../shared/button/button.module';
import { JumbotronModule } from '../../../shared/jumbotron/jumbotron.module';
import { AuditDiffFormatterComponent } from 'app/components/app-layout/shared/audit-history/formatters/audit-diff-formatter/audit-diff-formatter.component';
import { AuditSummaryComponent } from 'app/components/app-layout/shared/audit-history/formatters/audit-summary/audit-summary.component';
import { ChargeAuditReporterComponent } from 'app/components/app-layout/shared/audit-history/reporters/charge-audit-reporter/charge-audit-reporter.component';
import { SumPipeModule } from 'app/pipes/collection/sum.module';
import { LegfiCurrencyPipeModule } from 'app/pipes/format/legfi-currency.module';
import { WhereInPipeModule } from 'app/pipes/collection/where-in.module';
import { WhereKeyMatchesPipeModule } from 'app/pipes/collection/where-key-matches.module';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
    imports: [
        SharedModule,
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        LegfiDatePipeModule,
        LegfiJsonPipeModule,
        MatSelectModule,
        MatExpansionModule,
        MatTableModule,
        ButtonModule,
        JumbotronModule,
        SumPipeModule,
        LegfiCurrencyPipeModule,
        WhereInPipeModule,
        WhereKeyMatchesPipeModule,
        MatCheckboxModule,
    ],
    exports: [AuditHistoryComponent],
    declarations: [
        AuditHistoryComponent,
        AuditSummaryComponent,
        AuditDiffFormatterComponent,
        ChargeAuditReporterComponent,
    ],
    providers: [
        AdminService,
        LegfiDatePipe,
        LegfiJsonPipe,
    ],
})
export class AuditHistoryModule
{
}
