<ng-container *ngIf="showNavigation">
    <ng-container *ngIf="(pmc$ | async) as pmc">
        <a mat-button disableRipple sidenav-navigation-item
           description="{{ 'Property Management Dashboard' }}"
           [routerLink]="['/pmc', pmc.id, 'dashboard']"
           [routerLinkActive]="['mat-button-active']">
            <mat-icon [svgIcon]="'v2:fi-br-chart-mixed'"></mat-icon>
            {{ 'Dashboard' }}
        </a>

        <a mat-button disableRipple sidenav-navigation-item
           description="{{ 'Communities under Managed Properties' }}"
           [routerLink]="['/pmc', pmc.id, 'communities']"
           [routerLinkActive]="['mat-button-active']">
            <mat-icon [svgIcon]="'v2:fi-br-building'"></mat-icon>
            {{ 'Communities' }}
        </a>

        <a mat-button disableRipple sidenav-navigation-item
           description="{{ 'Requests from Managed Properties' }}"
           [routerLink]="['/pmc', pmc.id, 'requests']"
           [routerLinkActive]="['mat-button-active']">
            <mat-icon [svgIcon]="'v2:fi-br-question'"></mat-icon>
            {{ 'Requests' }}
        </a>

        <a mat-button disableRipple sidenav-navigation-item
           description="{{ 'Violations from Managed Properties' }}"
           [routerLink]="['/pmc', pmc.id, 'violations']"
           [routerLinkActive]="['mat-button-active']">
            <mat-icon [svgIcon]="'v2:fi-br-triangle-warning'"></mat-icon>
            {{ 'Violations' }}
        </a>
    </ng-container>

    <sidenav-navigation-loading *ngIf="!(pmc$ | async)"></sidenav-navigation-loading>
</ng-container>
