import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SidenavNavigationComponent } from './sidenav-navigation.component';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { SidenavNavigationItemDirective, SidenavNavigationLoadingDirective } from './sidenav-navigation.directive';

@NgModule({
    imports: [
        RouterModule,
        MatIconModule,
        MatButtonModule,
        CommonModule,
    ],
    declarations: [
        SidenavNavigationComponent,
        SidenavNavigationLoadingDirective,
        SidenavNavigationItemDirective,
    ],
    providers: [],
    exports: [
        SidenavNavigationComponent,
        SidenavNavigationLoadingDirective,
        SidenavNavigationItemDirective,
    ],
})
export class SidenavNavigationModule
{
}
