<ng-template #tagIcon let-color="color" let-filter="filter">
    <span *ngIf="!isRefreshingColor"
          class="fa-stack mr-1">
        <i class="fa fa-circle fa-stack-2x"
           [style.color]="color"></i>
        <i class="fa fa-circle-thin fa-stack-2x"
           [adjustColor]="color"
           [adjustment]="adjustColorValues.BORDER"></i>
        <i class="fa fa-stack-1x"
           [adjustColor]="color"
           [adjustment]="adjustColorValues.ICON"
           [ngClass]="{ 'fa-tag': !filter || filter === 'equals',
                        'fa-minus-circle': filter && filter === 'not_equals' }"></i>
    </span>
</ng-template>

<div class="tags-input mr-2"
     [ngClass]="{ 'tags-list': !isEditable || taggableMode === 'Table',
                  'with-tags': items.length > 0,
                  'btn-tags-input': hasButtonStyles,
                  'tags-loading': isLoading }">
    <div class="d-flex flex-nowrap align-items-center"
         [class.clickable]="hasDialog"
         (click)="viewAllItems()">
        <mat-spinner *ngIf="isLoading"
                     [diameter]="18"
                     [strokeWidth]="2"></mat-spinner>
        <tag-input #input
                   [theme]="'minimal'"
                   [editable]="isEditable && !hasDialog"
                   [removable]="isEditable && !hasDialog"
                   [placeholder]="placeholderText"
                   [secondaryPlaceholder]="emptyPlaceholderText"
                   [hideForm]="!isEditable || hasDialog"
                   [maxItems]="maxItems"
                   [onlyFromAutocomplete]="taggableMode === 'Filter'"
                   [onAdding]="onAdding"
                   [(ngModel)]="maxItems !== undefined ? renderedItems : items"
                   (onAdd)="onItemAdded($event)"
                   (onRemove)="onItemRemoved($event)"
                   (onSelect)="onItemSelected($event)"
                   (onFocus)="onInputFocused()">
            <ng-template let-item="item" let-index="index">
                <div class="tag__body text-nowrap"
                     [class.tag__outline]="item?.filter === 'not_equals'"
                     [class.tag__readonly]="item.readonly">
                    <ng-container *ngTemplateOutlet="tagIcon; context:{ color: item.taggableColor, filter: item.filter }">
                    </ng-container>
                    <span class="tag__text overflow-hidden pr-2"
                          [attr.title]="getTagDisplay(item)">{{ item.display }}</span>
                    <ng-container *ngIf="isEditable && !hasDialog && !item.readonly">
                        <span class="tag__delete fa-stack"
                              [attr.tabindex]="-1"
                              (click)="input.removeItem(item, index); refreshColors()">
                            <i class="fa fa-circle fa-stack-2x"></i>
                            <i class="fa fa-times fa-stack-1x"></i>
                        </span>
                    </ng-container>
                </div>
            </ng-template>
            <tag-input-dropdown [autocompleteObservable]="searchTags"
                                [minimumTextLength]="0"
                                [showDropdownIfEmpty]="taggableMode === 'Filter' || taggableMode === 'Editor'"
                                *ngIf="taggableMode === 'Filter' || taggableMode === 'Editor'"
                                [dynamicUpdate]="false"
                                [class.sm-dropdown]="taggableMode === 'Filter' || taggableMode === 'Editor'"
                                [appendToBody]="appendToBody">
                <ng-template let-index="index" let-item="item">
                    <div class="ng2-tag-input__dropdown-item">
                        <ng-container *ngTemplateOutlet="tagIcon; context:{ color: item.taggableColor }"></ng-container>
                        <span class="tag__text mr-1">{{ item.display }}</span>
                    </div>
                </ng-template>
            </tag-input-dropdown>
        </tag-input>

        <div class="tags-view">
            <ng-template #emptyReadonly>
                <span *ngIf="!isEditable && items.length === 0" class="text-muted">{{ emptyText }}</span>
            </ng-template>
            <ng-container *ngIf="isEditable && hasDialog && (!doSuppressHoverButton || items.length === 0); else emptyReadonly">
                <button #tagBtn
                        mat-icon-button
                        tabindex="0"
                        class="btn on-hover pencil ml-2">
                    <mat-icon *ngIf="items.length > 0">edit</mat-icon>
                    <mat-icon *ngIf="items.length === 0">add</mat-icon>
                </button>
            </ng-container>
        </div>
    </div>

    <div *ngIf="taggableMode === 'Filter' && isRadioGroupVisible"
         class="tag__conditionals pt-3 pb-2 px-3"
         [formGroup]="model">
        <mat-radio-group formControlName="condition">
            <mat-radio-button class="pb-2" value="equals"
                              [checked]="tagInput.selectedTag.filter === 'equals'">{{ filterEqualsText }}</mat-radio-button>
            <br>
            <mat-radio-button class="pb-2" value="not_equals"
                              [checked]="tagInput.selectedTag.filter === 'not_equals'">{{ filterNoEqualsText }}</mat-radio-button>
        </mat-radio-group>
    </div>
</div>
