import { NgModule } from '@angular/core';
import { FeatureDialogComponent } from './feature-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { SharedModule } from '../../../shared/shared.module';
import { ButtonModule } from '../../../shared/button/button.module';
import { MatIconModule } from '@angular/material/icon';
import { FeatureAllianceBankingModule } from '../alliance-banking/feature-alliance-banking.module';
import { FeaturePayablesModule } from '../payables/feature-payables.module';

@NgModule({
    imports: [
        MatDialogModule,
        SharedModule,
        ButtonModule,
        MatIconModule,
        FeatureAllianceBankingModule,
        FeaturePayablesModule,
    ],
    declarations: [FeatureDialogComponent],
    exports: [FeatureDialogComponent],
})
export class FeatureDialogModule
{
}
