import moment from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { Charge } from './charge';
import { Membership } from './membership';
import { PaymentGroup } from './payment-group';
import { User } from './user';
import { MessageBoardThread } from './message-board-thread';
import Moment = moment.Moment;

export class Note
{
    id: number;
    userId: number;
    modelId: number;
    modelType: string;
    note: string;
    authorName?: string;
    private: boolean;
    createdAt?: Moment;
    updatedAt?: Moment;
    deletedAt?: Moment;

    imageURL: string;

    model: Charge | Membership | PaymentGroup | MessageBoardThread;
    user: User;

    constructor(note: any) {
        if (note.id) {
            this.id = note.id;
        }
        if (note.userId) {
            this.userId = note.userId;
        }
        if (note.modelId) {
            this.modelId = note.modelId;
        }
        if (note.modelType) {
            this.modelType = note.modelType;
        }
        if (note.note) {
            this.note = note.note;
        }

        if (note.private) {
            this.private = note.private;
        }

        const timezone = LegFiJwtService.getTimezone();
        if (note.createdAt && !moment.isMoment(note.createdAt)) {
            this.createdAt = moment.utc(note.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (note.updatedAt && !moment.isMoment(note.updatedAt)) {
            this.updatedAt = moment.utc(note.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (note.deletedAt && !moment.isMoment(note.deletedAt)) {
            this.deletedAt = moment.utc(note.deletedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }

        if (note.imageURL) {
            this.imageURL = note.imageURL;
        }

        if (note.model && note.modelType !== null) {
            if (note.modelType.indexOf('Charge') !== -1) {
                this.model = new Charge(note.model);
            } else if (note.modelType.indexOf('Membership') !== -1) {
                this.model = new Membership(note.model);
            } else if (note.modelType.indexOf('PaymentGroup') !== -1) {
                this.model = new PaymentGroup(note.model);
            } else if (note.modelType.indexOf('PaymentGroup') !== -1) {
                this.model = new PaymentGroup(note.model);
            }
        }

        if (note.user) {
            this.user = new User(note.user);

            if (this.user.memberships.length) {
                const member = new Membership(this.user.memberships[0]);
                this.authorName = member.name;
            }
        }

        if (note.authorName) {
            this.authorName = note.authorName;
        }
    }
}
