import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '../../shared.module';
import { HeaderSwitcherComponent } from './header-switcher.component';
import { LFDropdownModule } from '../../dropdown/dropdown.module';
import { MatMenuModule } from '@angular/material/menu';
import { MaterialSearchModule } from '../../material-search/material-search.module';
import { ScrollingModule } from '@angular/cdk/scrolling';

@NgModule({
    imports: [
        // Shared
        SharedModule,
        LFDropdownModule.forRoot(),
        MatMenuModule,
        MatIconModule,
        MaterialSearchModule,
        ScrollingModule,
    ],
    declarations: [HeaderSwitcherComponent],
    exports: [HeaderSwitcherComponent],
    providers: [
    ],
})
export class HeaderSwitcherModule
{
}
