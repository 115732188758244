import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { JwtLegFiClaims } from '../../services/auth/jwt-legfi-claims.model';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { Router } from '@angular/router';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';

declare const Livewire: any;

@Component({
    selector: 'lf-app-layout',
    styleUrls: ['../../app.component.scss'],
    templateUrl: './app-layout.component.html',
})
export class AppLayoutComponent implements OnInit, AfterViewInit
{
    livewireScript: HTMLScriptElement;
    jwt: JwtLegFiClaims;
    isLoggedIn: boolean = false;
    bannerShowing = false;
    isInMobileMode = false;

    @ViewChild(MatSidenav) sidenav!: MatSidenav;

    constructor(
            private _observer: BreakpointObserver,
            private _router: Router,
    ) {
    }

    ngOnInit() {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();

        if (jwt !== null && !jwt.superUser) {
            this.jwt = jwt;
            this.isLoggedIn = true;
        } else {
            this.jwt = null;
            this.isLoggedIn = false;
        }

        this.injectLivewire();
    }

    injectLivewire() {
        const style = document.createElement('style');
        style.innerHTML = '<!-- Livewire Styles --><style >[wire\\:loading][wire\\:loading], [wire\\:loading\\.delay][wire\\:loading\\.delay], [wire\\:loading\\.inline-block][wire\\:loading\\.inline-block], [wire\\:loading\\.inline][wire\\:loading\\.inline], [wire\\:loading\\.block][wire\\:loading\\.block], [wire\\:loading\\.flex][wire\\:loading\\.flex], [wire\\:loading\\.table][wire\\:loading\\.table], [wire\\:loading\\.grid][wire\\:loading\\.grid], [wire\\:loading\\.inline-flex][wire\\:loading\\.inline-flex] {display: none;}[wire\\:loading\\.delay\\.none][wire\\:loading\\.delay\\.none], [wire\\:loading\\.delay\\.shortest][wire\\:loading\\.delay\\.shortest], [wire\\:loading\\.delay\\.shorter][wire\\:loading\\.delay\\.shorter], [wire\\:loading\\.delay\\.short][wire\\:loading\\.delay\\.short], [wire\\:loading\\.delay\\.default][wire\\:loading\\.delay\\.default], [wire\\:loading\\.delay\\.long][wire\\:loading\\.delay\\.long], [wire\\:loading\\.delay\\.longer][wire\\:loading\\.delay\\.longer], [wire\\:loading\\.delay\\.longest][wire\\:loading\\.delay\\.longest] {display: none;}[wire\\:offline][wire\\:offline] {display: none;}[wire\\:dirty]:not(textarea):not(input):not(select) {display: none;}:root {--livewire-progress-bar-color: #2299dd;}[x-cloak] {display: none !important;}</style>';
        document.head.appendChild(style);

        this.livewireScript = document.createElement('script');
        this.livewireScript.src = '//api/vendor/livewire/livewire.js';
        this.livewireScript.dataset.csrf = '';
        this.livewireScript.dataset.updateUri = '//api/livewire/update';
        this.livewireScript.onload = async () => {
            Livewire.hook('request', ({options}) => {
                options.headers['X-Wire-Extender'] = '';
            });
            Livewire.start();
        };
        document.body.appendChild(this.livewireScript);
    }

    /** Set up mat sidebar / menu functionality based on screen width */
    ngAfterViewInit() {
        setTimeout(() => {
            this._observer.observe(['(max-width: 945px)']).subscribe((res) => {
                if (res.matches) {
                    this.sidenav.mode = 'over';
                    this.sidenav.close().then(() => {
                        this.isInMobileMode = true;
                    });

                } else {
                    this.sidenav.mode = 'side';
                    this.sidenav.open().then(() => {
                        this.isInMobileMode = false;
                    });
                }
            });
        });
    }

    // if the page is in mobile view, they clicked to change routes; toggle the sidenav
    handleSidenavClickOnMobile(): void {
        if (this.sidenav.mode === 'over') {
            this.sidenav.close();
        }
    }

    updateBannerStatus(showing: boolean) {
        this.bannerShowing = showing;
    }
}
