import { NgModule } from '@angular/core';
import { AddHoaDialogComponent } from './add-hoa-dialog.component';
import { SharedModule } from '../shared.module';
import { MatDialogModule } from '@angular/material/dialog';
import { RecaptchaModule } from 'ng-recaptcha';

@NgModule({
    imports: [
        SharedModule,
        MatDialogModule,
        RecaptchaModule,
    ],
    declarations: [AddHoaDialogComponent],
    exports: [AddHoaDialogComponent],
})
export class AddHoaDialogModule
{
}
