/**
 * TODO: Replace locally defined widths with enum value
 * 1050 -> 1050,
 * 1000 -> 1050,
 * 950 -> 1050,
 * 900 -> 850/1050,
 * 850 -> 850,
 * 800 -> 850,
 * 750 -> 700,
 * 700 -> 700,
 * 600 -> 555/700,
 * 555 -> 555,
 * 500 -> 555,
 * 450 -> 450,
 * 400 -> 450,
 */

/**
 * Shared, standardized sizes for mat-dialog components across app
 */
export enum MatDialogSizes
{
    XS = '450px',
    SM = '555px',
    MD = '700px',
    LG = '850px',
    XL = '1050px',
    XXL = '1800px',
    FULL = 'calc(100% - 12px)',

    /** Common sizes based on function */
    /** Use with ConfirmActionDialogComponent */
    CONFIRM = '450px',
    /** Use with SelectedDialogComponent */
    SELECTED = '850px',
    /** Use with SelectedDialogComponent when overlaying another dialog */
    SELECTED_SM = '555px',
    /** Use for VideoDialogComponent height */
    VIDEO_H = '90vh',
}
