import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SharedDirectivesModule } from '../../shared/directives/shared-directives.module';
import { SidenavContentComponent } from './sidenav-content.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { SidenavActionsDirective, SidenavCloseDirective, SidenavContentContainerDirective, SidenavContentDirective, SidenavTitleDirective } from './sidenav-content.directive';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
    imports: [
        CommonModule,
        SharedDirectivesModule,
        MatToolbarModule,
        MatIconModule,
        MatListModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatMenuModule,
    ],
    declarations: [
        SidenavContentComponent,
        SidenavContentContainerDirective,
        SidenavTitleDirective,
        SidenavContentDirective,
        SidenavActionsDirective,
        SidenavCloseDirective,
    ],
    providers: [],
    exports: [
        SidenavContentComponent,
        SidenavContentContainerDirective,
        SidenavTitleDirective,
        SidenavContentDirective,
        SidenavActionsDirective,
        SidenavCloseDirective,
    ],
})
export class SidenavContentModule
{
}
