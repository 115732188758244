import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import moment from 'moment-timezone';
import { Routes } from '../../config/routes';
import { ApplicationHttpClient } from '../../components/shared/http/application-http-client';
import { JwtLegFiClaims } from '../auth/jwt-legfi-claims.model';
import { LegFiJwtService } from '../auth/legfi-jwt.service';
import { Organization } from '../../models/entities/organization';
import { Unit } from '../../models/entities/unit';
import { OrganizationPreference } from 'app/models/entities/organization-preference';
import { DashboardWidget } from 'app/models/entities/dashboard-widget';
import { CalendlyEvent } from '../../models/entities/calendly-event';
import { CustomField } from '../../models/entities/custom-field';
import Moment = moment.Moment;
import { GtmService } from '../gtm.service';

export class SimpleOrganization
{
    id: number;
    parentOrganizationId: number;
    name: string;
    checked: boolean = false;
    units: Unit[];
    deletedAt: Moment;

    constructor(org) {
        if (org.id) {
            this.id = org.id;
        }
        if (org.deletedAt) {
            const timezone = LegFiJwtService.getTimezone();
            this.deletedAt = moment.utc(org.deletedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (org.parentOrganizationId) {
            this.parentOrganizationId = org.parentOrganizationId;
        }
        if (org.name) {
            this.name = org.name;
        }

        if (org.units) {
            this.units = org.units.map((unit: any) => {
                return new Unit(unit);
            });
        }
    }
}

export interface OrgContentCopierRequest
{
    sourceOrgId: number;
    targetOrgIds: number[];
    fileIds: number[];
    violationStatusIds: number[];
    formIds: number[];
}

@Injectable({
    providedIn: 'root',
})
export class OrganizationService {
    constructor(
            private _http: ApplicationHttpClient,
            private _gtm: GtmService,
    ) { }

    /**
     * Fetches the list of DashboardWidgets for the current org and user
     */
    public getOrgDashboardWidgets(): Observable<DashboardWidget[]> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(Routes.LegFiCore.OrganizationDashboardWidgets(jwt.orgId, jwt.id));
        return this._http.get(url).pipe(map((response: Object[]) => {
            return response.map((widget) => {
                return new DashboardWidget(widget);
            });
        }));
    }

    /**
     * Updates dashboard widget ordering and enabled state
     */
    public updateOrgDashboardWidgetOrder(data: {
        id: number,
        rank: number,
        isEnabled: boolean
    }[]): Observable<DashboardWidget[]> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(Routes.LegFiCore.OrganizationDashboardWidgets(jwt.orgId, jwt.id));
        return this._http.post(url, JSON.stringify({widgets: data})).pipe(map((response: Object[]) => {
            return response.map((widget) => {
                return new DashboardWidget(widget);
            });
        }));
    }

    /**
     * @param {number} organizationId
     * @param {string[]} withs
     * @returns {Observable<Organization>}
     */
    public getOrganizationById(organizationId: number, withs: string[]): Observable<Organization> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const withString = withs.length > 0 ? '?with=' + withs.join(',') : '';
        const url: string = Routes.MakeLegFiCoreUrl(Routes.LegFiCore.Organization(organizationId || jwt.orgId)) + withString;

        return this._http.get(url).pipe(map((response: Object) => {
            return new Organization(response);
        }));
    }

    public getStripeLink(orgId: number, accountId: number): Observable<any> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.OrgBankAccount(orgId || jwt.orgId, accountId) + '/verify/link',
        );

        return this._http.get(url);
    }

    /**
     * Updates membership details from Core.
     * @param requestBody
     * @returns {Observable<Organization>}
     */
    public updateOrganization(requestBody: Object): Observable<Organization> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        return this._http.patch(
                Routes.MakeLegFiCoreUrl(
                        Routes.LegFiCore.Organization(jwt.orgId),
                ),
                JSON.stringify(requestBody),
        ).pipe(map((response: Object) => {
            return new Organization(response);
        }));
    }

    /**
     * Updates membership details from Core.
     * @param requestBody
     * @returns {Observable<Organization>}
     */
    public updateOrganizationPreferences(requestBody: Object): Observable<OrganizationPreference> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        return this._http.patch(
                Routes.MakeLegFiCoreUrl(
                        Routes.LegFiCore.OrganizationPreferences(jwt.orgId),
                ),
                JSON.stringify(requestBody),
        ).pipe(map((response: Object) => {
            return new OrganizationPreference(response);
        }));
    }

    /**
     * @param {number} organizationId
     * @param {{paymentMethodType:string, paymentMethodId:number}} paymentMethod
     * @returns {Observable<Organization>}
     */
    public updatePaymentMethod(
            organizationId: number,
            paymentMethod: { paymentMethodType: string, paymentMethodId: number },
    ): Observable<Organization> {

        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.OrgDefaultPaymentMethod(organizationId || jwt.orgId),
        );

        return this._http.put(url, JSON.stringify(paymentMethod)).pipe(map((response: Object) => {
            return new Organization(response);
        }));
    }

    public fixOrgExpiration(organizationId: number): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.OrganizationExpirationCheckout(organizationId || jwt.orgId),
        );

        return this._http.post(url, JSON.stringify({}));
    }

    /**
     * @param {number} organizationId
     * @returns {Observable<Object>}
     */
    public getDashboardSummary(organizationId: number): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.OrgDashboard(organizationId || jwt.orgId),
        );

        return this._http.get(url);
    }

    /**
     * @param {number} organizationId
     * @param {any} options
     * @returns {Observable<Object>}
     */
    public getCollectionProgress(organizationId: number, options?: any): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const params: any[] = [];
        if (options) {
            for (const prop in options) {
                if (options.hasOwnProperty(prop)) {
                    params.push(prop + '=' + options[prop]);
                }
            }
        }
        const queryString: string = params.length > 0 ? '?' + params.join('&') : '';

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.CollectionProgress(organizationId || jwt.orgId) + queryString,
        );

        return this._http.get(url);
    }

    /**
     * @param {number} organizationId
     * @param {any} options
     * @returns {Observable<Object>}
     */
    public getPayments(organizationId: number, options?: any): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const params: any[] = [];
        if (options) {
            for (const prop in options) {
                if (options.hasOwnProperty(prop)) {
                    params.push(prop + '=' + options[prop]);
                }
            }
        }
        const queryString: string = params.length > 0 ? '?' + params.join('&') : '';

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.PaymentsGraph(organizationId || jwt.orgId) + queryString,
        );

        return this._http.get(url);
    }


    /**
     * @returns {Observable<SimpleOrganization[]>}
     */
    public getSimpleOrgList(userId?: number, filtered?: boolean): Observable<SimpleOrganization[]> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        let url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.SimpleOrgList(),
        );
        if (userId !== null) {
            url = url + '?userId=' + userId;
            if (filtered) {
                url = url + '&filtered=true';
            }
        } else {
            if (filtered) {
                url = url + '?filtered=true';
            }
        }

        return this._http.get(url).pipe(map((response: Object[]) => {
            return response.map((org) => {
                return new SimpleOrganization(org);
            });
        }));
    }

    /**
     * @param {string} term
     * @returns {Observable}
     */
    public searchOrgsByName(term: string): Observable<any[]> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        let url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.SearchOrgList(),
        );
        if (term) {
            url = url + '?term=' + term;
        }

        return this._http.get(url).pipe(map((response: Object[]) => {
            return response.map((org) => {
                return new SimpleOrganization(org);
            });
        }));
    }

    /**
     * Bulk imports currently available for transactions, members
     * @param {number} organizationId
     * @param {string} whichModel
     * @returns {Observable<Object>}
     */
    public getSupportedFieldsForBulkImport(organizationId: number, whichModel: string): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        let url: string = '';
        if (whichModel === 'transaction') {
            url = Routes.MakeLegFiCoreUrl(
                    Routes.LegFiCore.TransactionsBulkImportSupportedColumns(organizationId || jwt.orgId),
            );
        } else if (whichModel === 'unit') {
            url = Routes.MakeLegFiCoreUrl(
                    Routes.LegFiCore.UnitsBulkImportSupportedColumns(organizationId || jwt.orgId),
            );
        }

        return this._http.get(url);
    }

    /**
     * @param type entity e.g. 'member' 'unit' 'vendor'
     * @returns {Observable<Object>}
     */
    public getCustomFieldsForOrganization(type: string): Observable<CustomField[]> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.CustomFieldsForOrg(jwt.orgId, type),
        );

        return this._http.get(url).pipe(map((response: Object[]) => {
            return response.map((field) => new CustomField(field));
        }));
    }

    /**
     * @param type entity e.g. 'member' 'unit' 'vendor'
     * @param {Object} requestBody
     * @returns {Observable<Object>}
     */
    public createCustomFieldForOrganization(type: string, requestBody: { name: string }): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        return this._http.post(
                Routes.MakeLegFiCoreUrl(
                        Routes.LegFiCore.CustomFieldsForOrg(jwt.orgId, type),
                ),
                JSON.stringify(requestBody),
        );
    }

    /**
     * @param type entity e.g. 'member' 'unit' 'vendor'
     * @param fieldId
     * @param requestBody
     */
    public editCustomFieldForOrganization(
            type: string,
            fieldId: number,
            requestBody: { name: string },
    ): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.CustomFieldForOrg(jwt.orgId, type, fieldId),
        );

        return this._http.patch(url, JSON.stringify(requestBody));
    }

    /**
     * @param type entity e.g. 'member' 'unit' 'vendor'
     * @param {number} fieldId
     * @returns {Observable<Object>}
     */
    public deleteCustomFieldForOrganization(type: string, fieldId: number): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.CustomFieldForOrg(jwt.orgId, type, fieldId),
        );

        return this._http.delete(url);
    }


    /**
     * @param orgId
     * @returns {any}
     */
    public deleteOrganization(orgId: number): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(Routes.LegFiCore.Organization(orgId));

        return this._http.delete(url);
    }

    /**
     * @param orgId
     * @returns {any}
     */
    public restoreOrganization(orgId: number): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(Routes.LegFiCore.RestoreOrganization(orgId));

        return this._http.post(url, '');
    }

    /**
     * @param {number} orgId
     * @param {any} request
     * @returns {any}
     */
    public sendStatements(orgId: number, request: any): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(Routes.LegFiCore.Organization(orgId || jwt.orgId)) + '/send-statements';

        return this._http.post(url, JSON.stringify(request));
    }

    /**
     * Updates just the has_logo setting for this org
     * @param {number} orgId
     * @returns {Observable}
     */
    public deleteOrganizationLogo(orgId: number): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        return this._http.patch(
                Routes.MakeLegFiCoreUrl(
                        Routes.LegFiCore.DeleteOrganizationLogo(orgId || jwt.orgId),
                ),
                JSON.stringify(orgId),
        );
    }

    /**
     * @param {number} orgId
     * @param {string} token
     * @returns {Observable<Object>}
     */
    public verifyOrgSignup(orgId: number, token: string): Observable<Object> {
        const body = {vtoken: token};
        return this._http.post(
                Routes.MakeLegFiCoreUrl(
                        Routes.LegFiCore.VerifyOrganization(orgId),
                ),
                JSON.stringify(body),
        );
    }

    verifyAbilityToSendMail(orgId: number): Observable<any> {
        return this._http.get(
                Routes.MakeLegFiCoreUrl(
                        Routes.LegFiCore.Organization(orgId) + '/mail/verify',
                ),
        );
    }

    storeDemoInfo(orgId: number, body: CalendlyEvent): Observable<any> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.Organization(orgId || jwt.orgId) + '/demo-info',
        );

        return this._http.post(url, JSON.stringify(body)).pipe(
                map(() => {
                    this._gtm.demoScheduled({
                        orgId: jwt.orgId,
                        addedBy: jwt.id,
                    });
                    return;
                }),
        );
    }

    getDemoInfo(orgId: number): Observable<any> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(Routes.LegFiCore.Organization(orgId || jwt.orgId)) + '/demo-info';

        return this._http.get(url);
    }

    public enrollInLockbox(depositAccountId: number): Observable<any> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(Routes.LegFiCore.OrganizationLockbox(jwt.orgId));

        return this._http.post(url, JSON.stringify({deposit_bank_account_id: depositAccountId}));
    }

    public unenrollFromLockbox(): Observable<any> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(Routes.LegFiCore.OrganizationLockbox(jwt.orgId));

        return this._http.delete(url);
    }

    public copyOrgContent(request: OrgContentCopierRequest): Observable<void> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(Routes.LegFiCore.Organization(request.sourceOrgId)) + '/content-copy';

        return this._http.post(url, JSON.stringify(request));
    }
}
