import { NgModule } from '@angular/core';
import { MaterialSearchModule } from '../../material-search/material-search.module';
import { DropdownSearchComponent } from './dropdown-search.component';
import { SharedModule } from '../../shared.module';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { LFDropdownModule } from '../../dropdown/dropdown.module';
import { MatMenuModule } from '@angular/material/menu';
import { LegfiCurrencyPipeModule } from '../../../../pipes/format/legfi-currency.module';

@NgModule({
    imports: [
        // Shared
        CommonModule,
        SharedModule,
        RouterModule,
        LFDropdownModule,
        MatMenuModule,
        MaterialSearchModule,
        LegfiCurrencyPipeModule,
    ],
    declarations: [DropdownSearchComponent],
    exports: [DropdownSearchComponent],
    providers: [
    ],
})
export class DropdownSearchModule
{
}
