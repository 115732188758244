import moment from 'moment-timezone';
import { LegFiJwtService } from '../../../services/auth/legfi-jwt.service';
import { Membership } from '../membership';

export class FormSubmissionComment
{
    id: number;
    submissionId: number;
    membershipId: number;
    message: string;
    isAdmin: boolean;
    createdAt: string;

    membership: Membership;

    constructor(comment: any) {
        this.id = comment.id;
        this.submissionId = comment.submissionId;
        this.membershipId = comment.membershipId;
        this.message = comment.message;
        this.isAdmin = comment.isAdmin || false;

        const timezone = LegFiJwtService.getTimezone();
        if (comment.createdAt) {
            const createdAt = moment.utc(comment.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
            this.createdAt = createdAt.format('MMM D, YYYY, hh:mm A');
        }

        if (comment.membership) {
            this.membership = new Membership(comment.membership);
        }
    }
}
