import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ChargesService } from '../../../../services/charges/charges.service';
import { PaymentGroupsService } from '../../../../services/payment/payment-groups.service';
import { CurrencyInputModule } from '../../../shared/currency-input/currency-input.module';
import { LegfiCurrencyPipe } from '../../../../pipes/format/legfi-currency.pipe';
import { LegfiCurrencyPipeModule } from '../../../../pipes/format/legfi-currency.module';
import { LegfiDatePipe } from '../../../../pipes/format/legfi-date.pipe';
import { LegfiDatePipeModule } from '../../../../pipes/format/legfi-date.module';
import { NewlineToBreakPipeModule } from '../../../../pipes/format/nl2br.module';
import { NewlineToBreakPipe } from '../../../../pipes/format/nl2br.pipe';
import { RangeSliderModule } from '../../../shared/range-slider/range-slider.module';
import { SingleUnitSelectorModule } from '../../shared/single-unit-selector/single-unit-selector.module';
import { RecordOwnerPaymentComponent } from './record-owner-payment.component';
import { OwnersService } from '../../../../services/units/owners.service';
import { SingleOwnerSelectorModule } from '../../shared/single-owner-selector/single-owner-selector.module';
import { DatepickerModule } from '../../../shared/datepicker/datepicker.module';
import { ToggleSwitchModule } from 'app/components/shared/toggle-switch/toggle-switch.module';
import { BankAccountsService } from 'app/services/financial-accounts/bank-accounts.service';
import { OrganizationService } from '../../../../services/organization/organization.service';
import { PrintService } from '../../../../services/print.service';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
    imports: [
        // Shared
        SharedModule,
        // Dependencies
        CurrencyInputModule,
        DatepickerModule,
        LegfiCurrencyPipeModule,
        LegfiDatePipeModule,
        NgbModule,
        NewlineToBreakPipeModule,
        RangeSliderModule,
        SingleOwnerSelectorModule,
        SingleUnitSelectorModule,
        ToggleSwitchModule,
        MatDatepickerModule,
        MatMomentDateModule,
        MatSlideToggleModule,
    ],
    declarations: [RecordOwnerPaymentComponent],
    exports: [RecordOwnerPaymentComponent],
    providers: [
        // Services
        BankAccountsService,
        ChargesService,
        OwnersService,
        PaymentGroupsService,
        OrganizationService,
        PrintService,
        // Pipes
        LegfiCurrencyPipe,
        LegfiDatePipe,
        NewlineToBreakPipe,
        MatDatepickerModule,
        MatMomentDateModule,
    ],
})
export class RecordOwnerPaymentModule
{
}
