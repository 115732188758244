<!-- loader --->
<ng-template #loading let-showLoader="loading">
    <div *ngIf="showLoader" class="d-loading card-body">
        <div class="loader">Loading...</div>
    </div>
</ng-template>

<ng-container *ngTemplateOutlet="loading; context: { loading: isLoading && !config }"></ng-container>

<div *ngIf="!!config" class="form-group">
    <label *ngIf="!!label">{{ label }}</label>
    <ng-container *ngTemplateOutlet="loading; context: { loading: isLoading }"></ng-container>
    <editor apiKey="{{apiKey}}"
            class="animated fadeIn"
            [hidden]="isLoading"
            [formControl]="control"
            [init]="config"
            (onInit)="handleEditorInit($event)"></editor>
    <ng-content select="lf-control-messages"></ng-content>

    <input *ngIf="_uploader"
           #attachFile
           [uploader]="_uploader"
           class="custom-file-input"
           name="file"
           ng2FileSelect
           type="file"/>
</div>
