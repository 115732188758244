import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ButtonModule } from '../button/button.module';
import { HeaderBarComponent } from './header-bar.component';
import { SharedModule } from '../shared.module';
import { KycModalModule } from '../../app-layout/shared/kyc-modal/kyc-modal.module';
import { LoadingModalModule } from '../../app-layout/shared/loading-modal/loading-modal.module';
import { ModalModule } from '../modal/modal.module';
import { NoticeBarModule } from '../../app-layout/shared/notice-bar/notice-bar.module';
import { HeaderSwitcherModule } from './header-switcher/header-switcher.module';
import { RecordOwnerPaymentModule } from '../../app-layout/payments/record-owner-payment/record-owner-payment.module';
import { LFDropdownModule } from '../dropdown/dropdown.module';
import { DropdownSearchModule } from '../search/dropdown-search/dropdown-search.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LegfiDatePipeModule } from '../../../pipes/format/legfi-date.module';
import { AuditHistoryModule } from '../../app-layout/shared/audit-history/audit-history.module';
import { AuthService } from '../../../services/auth/auth.service';
import { MembersService } from '../../../services/members/members.service';
import { OrganizationService } from '../../../services/organization/organization.service';
import { AdminService } from '../../../services/admin/admin.service';
import { MatMenuModule } from '@angular/material/menu';
import { PageMenuModule } from '../../app-layout/shared/page-menu/page-menu.module';
import { AddHoaDialogModule } from '../add-hoa-dialog/add-hoa-dialog.module';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
    imports: [
        // Shared
        SharedModule,
        // Dependencies
        AddHoaDialogModule,
        AuditHistoryModule,
        DropdownSearchModule,
        MatDialogModule,
        KycModalModule,
        LegfiDatePipeModule,
        LFDropdownModule.forRoot(),
        LoadingModalModule,
        MatMenuModule,
        MatProgressSpinnerModule,
        ModalModule,
        NoticeBarModule,
        HeaderSwitcherModule,
        PageMenuModule,
        RecordOwnerPaymentModule,
        MatIconModule,
        ButtonModule,
    ],
    exports: [
        HeaderBarComponent,
    ],
    declarations: [HeaderBarComponent],
    providers: [
        // Services
        AuthService,
        MembersService,
        OrganizationService,
        AdminService,
    ],
})
export class HeaderBarModule
{
}
