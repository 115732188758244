import { Component, Input, OnInit } from '@angular/core';
import { PropertyManagementCompany } from '../../../../models/entities/property-management-company';
import { UntypedFormControl } from '@angular/forms';
import { JwtLegFiClaims } from '../../../../services/auth/jwt-legfi-claims.model';
import { LegFiJwtService } from '../../../../services/auth/legfi-jwt.service';
import { IntercomService } from '../../../../services/intercom.service';
import { AuthService } from '../../../../services/auth/auth.service';
import { Organization } from '../../../../models/entities/organization';
import { PropertyManagementCompanyService } from '../../../../services/property-management-company/property-management-company.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GrowlerService } from '../../../../services/growler.service';
import { debounceTime } from 'rxjs/operators';
import { OrganizationDataService } from '../../../../services/organization/organization-data.service';
import { Router } from '@angular/router';
import { SidenavContentService } from '../../sidenav/sidenav-content/sidenav-content.service';
import { NotificationsSidenavComponent } from '../../notifications/notifications-sidenav/notifications-sidenav.component';

@UntilDestroy()
@Component({
    selector: 'app-header-menu',
    templateUrl: './header-menu.component.html',
    styleUrls: ['./header-menu.component.scss'],
})
export class HeaderMenuComponent implements OnInit
{
    @Input() pmc: PropertyManagementCompany;

    organizationSearch = new UntypedFormControl('');
    organizations: Organization[] = [];
    filterTerm = '';
    isSearchingOrganizations = false;

    jwt: JwtLegFiClaims;
    hasSupportPermission = false;

    constructor(
            private _propertyManagementCompanyService: PropertyManagementCompanyService,
            private _organizationDataService: OrganizationDataService,
            private _authService: AuthService,
            private _sidenav: SidenavContentService,
            private _intercom: IntercomService,
            private _router: Router,
            private _growler: GrowlerService,
    ) {
    }

    get filteredResults() {
        return this.organizations.filter((item) => {
            const term = this.filterTerm;
            if (term === '') {
                return true;
            }

            let value = item.name.trim().toLowerCase();
            value += item.id;
            return value.indexOf(term) > -1;
        });
    }

    ngOnInit() {
        this.jwt = LegFiJwtService.read();
        this.hasSupportPermission = LegFiJwtService.doesUserHaveModulePermission(
                'support',
                false,
        );

        this._propertyManagementCompanyService.getPropertyManagementOrganizations().pipe(untilDestroyed(this)).subscribe({
            next: (res) => this.organizations = res.data,
            error: () => this._growler.oops('Unable to load organizations.'),
        });

        this.organizationSearch.valueChanges.pipe(debounceTime(500), untilDestroyed(this)).subscribe({
            next: (term) => this.filterTerm = term ? term.trim().toLowerCase() : '',
        });
    }

    goToOrganization(event: Organization) {
        this._organizationDataService.switchOrganization(event.id).pipe(untilDestroyed(this)).subscribe({
            next: ([url, isExternalRedirect]: [string, boolean]) => {
                if (isExternalRedirect) {
                    window.location.href = url;
                } else {
                    // noinspection JSIgnoredPromiseFromCall
                    this._router.navigateByUrl(url, {skipLocationChange: true});
                }
            },
            error: () => {
                this._growler.oops('There was a problem switching current organization.');
            },
        });
    }

    sendIntercomMessage() {
        this._intercom.newMessage();
    }

    logout() {
        this._authService.logout();
    }

    openNotifications() {
        this._sidenav.open(NotificationsSidenavComponent);
    }
}
