import { ChangeDetectorRef, Component, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Tag } from '../../../../models/entities/tag';
import { TaggableTypes, TagsDataService } from '../../../../services/tags/tags-data.service';
import { GrowlerService } from '../../../../services/growler.service';
import { combineLatestWith, forkJoin, mergeMap, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { TagInputComponent } from 'ngx-chips';
import { TagsService } from '../../../../services/tags/tags.service';
import { AdjustColorValues } from '../../../../directives/adjust-color/adjust-color.directive';
import { TagModel } from 'ngx-chips/core/tag-model';
import { ColorSourcesEnum } from '../../../../enums/color-sources.enum';

export interface TagsDialogInput {
    items: Tag[];
    obs: Observable<Tag[]>;
    taggableType: TaggableTypes;
    taggableIds: number[];
    readonly: boolean;
    placeholder: string;
    secondaryPlaceholder: string;
    hasArchived: boolean;
    sharedItems?: Tag[];
}

export interface TagsDialogOutput {
    success: boolean;
    taggableIds: number[];
    items: Tag[];
}

@UntilDestroy()
@Component({
    selector: 'app-tags-dialog',
    templateUrl: './tags-dialog.component.html',
    styleUrls: [
        '../tags.component.scss',
        './tags-dialog.component.scss'
    ],
})
export class TagsDialogComponent implements OnInit {
    @ViewChild('dialogTagInput') tagInput: TagInputComponent;

    readonly autocompleteInitialCount = 100;
    readonly autocompleteMaxAfterSearch = 25;
    readonly adjustColorValues = AdjustColorValues;

    items: Tag[] = [];

    hasLoaded = false;
    isSaving = false;
    isReadonly = false;
    isRefreshingColor = false;
    isDisplayingSharedItems = false;

    constructor(private _dialogRef: MatDialogRef<TagsDialogComponent>,
                private _growler: GrowlerService,
                private _cd: ChangeDetectorRef,
                private _tagsDataService: TagsDataService,
                private _tagsService: TagsService,
                @Inject(MAT_DIALOG_DATA) public data: TagsDialogInput) {
    }

    get sharedTagsTooltip() {
        const type = this.data.taggableType.toLowerCase();
        return `When enabled, only include tags that are added to every selected ${type}. Otherwise, include all tags added to any selected ${type}.`;
    }

    get dialogTitle() {
        let title = this.isReadonly ? 'View' : (this.data.items.length > 0 ? 'Edit' : 'Add');
        title += this.isDisplayingSharedItems  ?  ' Shared' : '';
        title += ' ' + this.data.taggableType;

        return title + ' Tags';
    }

    get dialogItems() {
        if (!this.data.sharedItems) {
            return [...this.data.items];
        }

        const added = {};
        const items = this.isDisplayingSharedItems ? this.data.sharedItems : this.data.items.filter((tag) => added.hasOwnProperty(tag.tag) ? false : added[tag.tag] = true);
        // We are mapping to new tags, so we don't get confused with tag ids that differ across multiple taggable ids

        return [
            ...items.map((tag) => new Tag({
                tag: tag.tag,
                taggableColor: tag.taggableColor,
                internalUse: tag.internalUse,
            })),
        ].sort((a, b) => b.internalUse > 0 ? 0 : (a.internalUse > 0 ? -1 : 1));
    }

    @HostListener('keydown.esc') onEsc() {
        this._dialogRef.close();
    }

    ngOnInit(): void {
        this.isReadonly = this.data.readonly;
        this.isDisplayingSharedItems = this.data.sharedItems !== undefined || false;
        this.items = this.dialogItems;
    }

    searchTags = (text: string): Observable<Tag[]> => {
        return this.data.obs.pipe(
                map((tags) => {
                    tags = tags.filter((tag) => !tag.internalUse);

                    if (text.trim() === '') {
                        return tags.slice(0, this.autocompleteInitialCount);
                    }

                    const str = text.toLocaleLowerCase().trim();
                    return tags.filter((tag) => tag.tag.toLocaleLowerCase().indexOf(str) > -1)
                            .slice(0, this.autocompleteMaxAfterSearch);
                }),
        );
    }

    onAdding(model: string | TagModel): Observable<TagModel> {
        if (typeof model === 'string') {
            const max = Object.keys(ColorSourcesEnum).length - 1;
            const index = Math.floor(Math.random() * (max));
            const taggableColor = Object.values(ColorSourcesEnum)[index];
            const tag = new Tag({tag: model, taggableColor});
            return of(tag);
        }

        return of(model);
    }

    onSubmit() {
        // ensure that any remaining tagInput value gets converted to a new tag before submit
        if (this.tagInput.inputForm.inputText.trim() !== '') {
            this.tagInput.onFormSubmit().then();
        }

        // if missing data for some reason, error out!
        if (!this.data.taggableType || !this.data.taggableIds) {
            this._dialogRef.close();
            this._growler.oops('Could not update tags.');

            return;
        }

        // if item in items array does not exist on initial data.items (or data.sharedItems array), we are adding the tag(s)
        const initialItems = this.isDisplayingSharedItems ? this.data.sharedItems : this.data.items;
        const toAdd = this.items.filter((item) => !item.internalUse && initialItems.find((i) => i.tag === item.tag) === undefined);

        // if item from data.items does not exist in items array, we are removing the tag(s)
        // note even for data.sharedItems we want to check against the full initial data.items array for deletions
        const toDelete = this.data.items.filter((item) => {
            if (!this.isDisplayingSharedItems) {
                return this.items.find((i) => i.tag === item.tag) === undefined;
            }

            // we need to first find items that are shared, then if they have been removed
            return this.data.sharedItems.find((i) => i.tag === item.tag) !== undefined && this.items.find((i) => i.tag === item.tag) === undefined;
        }).filter((i) => !i.internalUse).map((item) => item.id);

        // if we are not adding/removing items, no change so let's cancel instead
        if (toAdd.length === 0 && toDelete.length === 0) {
            this._dialogRef.close();
            return;
        }

        this.isSaving = true;
        this._tagsService.patchTagsForModel(
            this.data.taggableType,
            this.data.taggableIds,
            toAdd,
            toDelete,
            this.data.hasArchived
        ).pipe(
            mergeMap(() => this._tagsDataService.getOrganizationTagsByType(this.data.taggableType, this.data.hasArchived)),
            mergeMap(() => this._tagsService.getTagsForType(this.data.taggableType, this.data.taggableIds, this.data.hasArchived)),
            untilDestroyed(this),
        ).subscribe({
            next: (tags) => {
                const message = `Tags updated for ${this.data.taggableType.toLowerCase() + (this.data.sharedItems !== undefined ? ' selection' : '')}.`;
                const output: TagsDialogOutput = {
                    success: true,
                    taggableIds: this.data.taggableIds,
                    items: tags,
                };

                this._growler.success('Success', message);
                this.isSaving = false;
                this._dialogRef.close(output);
            },
            error: () => {
                this._growler.oops('Could not update tags.');
                this.isSaving = false;
                this._dialogRef.close();
            },
        });
    }

    refreshColors() {
        this.isRefreshingColor = true;
        setTimeout(() => this.isRefreshingColor = false, 0);
    }

    toggleSharedItems(isChecked: boolean) {
        this.isDisplayingSharedItems = isChecked;
        this.items = this.dialogItems;
    }
}
