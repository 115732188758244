import { ChangeDetectorRef, Component, ElementRef, HostBinding, OnInit, Renderer2, ViewChild } from '@angular/core';
import { SidenavContentContainerDirective } from './sidenav-content.directive';
import { SidenavContentService } from './sidenav-content.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { iif, of } from 'rxjs';
import { delay, switchMap } from 'rxjs/operators';
import { MatToolbar } from '@angular/material/toolbar';

@UntilDestroy()
@Component({
    selector: 'app-sidenav-content',
    styleUrls: ['./sidenav-content.component.scss'],
    templateUrl: './sidenav-content.component.html',
})
export class SidenavContentComponent implements OnInit
{
    @HostBinding('class') classValue = 'pmc-sidenav-content';

    title = '';
    isLoadingContent = true;
    showActions = false;

    private _headerToolbar: ElementRef;
    private _actionsToolbar: ElementRef;

    constructor(
        private _sidenav: SidenavContentService,
        private _renderer: Renderer2,
        private _cd: ChangeDetectorRef,
    ) {
    }

    @ViewChild(SidenavContentContainerDirective, { static: true }) set content(content: SidenavContentContainerDirective) {
        if (content) {
            this._sidenav._container = content.viewContainerRef;
        }
    }

    @ViewChild('sidenavHeader', { static: true }) set header(content: MatToolbar) {
        if (content) {
            this._headerToolbar = content._elementRef;
            this._renderer.addClass(this._headerToolbar.nativeElement, 'sidenav-content-header');
            this._renderer.addClass(this._headerToolbar.nativeElement, 'animated');
            this._renderer.addClass(this._headerToolbar.nativeElement, 'fadeIn');
        }
    }

    @ViewChild('sidenavActions', { static: true }) set actions(content: MatToolbar) {
        if (content) {
            this._actionsToolbar = content._elementRef;
            this._renderer.addClass(this._actionsToolbar.nativeElement, 'sidenav-content-actions');
            this._renderer.addClass(this._actionsToolbar.nativeElement, 'animated');
            this._renderer.addClass(this._actionsToolbar.nativeElement, 'fadeIn');
        }
    }

    ngOnInit() {
        this._sidenav._loading$.pipe(switchMap((loading) => iif(() => loading, of(loading), of(loading).pipe(delay(900)))), untilDestroyed(this)).subscribe({
            next: (loading) => this.isLoadingContent = loading,
        });

        /** When the title subject pushes a new value, update the title text displayed in the sidenav header */
        this._sidenav._title$.pipe(untilDestroyed(this)).subscribe((elementRef) => {
            if (elementRef) {
                const nodes = elementRef.nativeElement.childNodes;
                this.title = nodes[0].textContent;
                this._cd.detectChanges();

                elementRef.nativeElement.removeChild(elementRef.nativeElement.firstChild);
            }
        });

        /**
         * When the actions subject pushes a new value, we determine if actions are included
         * in the sidenav (or actions are removed when closing the sidenav)
         */
        this._sidenav._actions$.pipe(untilDestroyed(this)).subscribe((elementRef) => {
            const sidenavActionToolbar = this._actionsToolbar.nativeElement;
            if (elementRef) {
                // move each child node (like mat-buttons) to the actions toolbar
                while (elementRef.nativeElement.firstChild) {
                    this._renderer.appendChild(sidenavActionToolbar, elementRef.nativeElement.firstChild);
                }
                this.showActions = true;
            } else {
                // remove child nodes and resets the actions toolbar
                while (sidenavActionToolbar.firstChild) {
                    sidenavActionToolbar.removeChild(sidenavActionToolbar.lastChild);
                }
                this.showActions = false;
            }
            this._cd.detectChanges();
        });
    }

    closeContent() {
        this._sidenav.close();
    }
}
