import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class PrintService {
    public printElement(windowTitle: string, elementId: string, excludeClass: string = 'd-print-none') {
        const element = document.getElementById(elementId);
        const clonedElement = element.cloneNode(true) as HTMLElement;
        this.removeElementsByClass(clonedElement, excludeClass);

        const printContents = clonedElement.innerHTML;
        const stylesHtml = this.getTagsHtml('style');
        const linksHtml = this.getTagsHtml('link').replace('href="styles.css"', 'href="' + window.origin + '/styles.css"');

        const popupWin = window.open('', '_blank', 'top=0,left=0,height=600,width=auto');
        popupWin.document.open();
        popupWin.document.write(`
            <html>
                <head>
                    <title>${windowTitle}</title>
                    ${linksHtml}
                    ${stylesHtml}
                </head>
                <body onload="window.print(); window.close()">
                    ${printContents}
                </body>
            </html>
        `);
        popupWin.document.close();
    }

    private removeElementsByClass(element: HTMLElement, className: string): void {
        const elements = element.getElementsByClassName(className);
        const elementsArray = Array.from(elements);
        elementsArray.forEach(el => el.parentNode?.removeChild(el));
    }

    private getTagsHtml(tagName: keyof HTMLElementTagNameMap): string {
        const htmlStr: string[] = [];
        const elements = document.getElementsByTagName(tagName);
        for (let idx = 0; idx < elements.length; idx++) {
            htmlStr.push(elements[idx].outerHTML);
        }
        return htmlStr.join('\r\n');
    }
}
