import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { LegFiJwtService } from 'app/services/auth/legfi-jwt.service';
import { RecaptchaComponent } from 'ng-recaptcha';
import { GrowlerService } from 'app/services/growler.service';
import { AuthService } from 'app/services/auth/auth.service';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';

@Component({
    selector: 'app-add-hoa-dialog',
    templateUrl: './add-hoa-dialog.component.html',
})
export class AddHoaDialogComponent implements OnInit
{
    newHoaGroupName: string = '';
    isRegistering = false;
    captchaToken: string = null;
    captchaValid = false;
    siteKey = environment.RecaptchaSiteKey;
    readonly environment = environment;

    @ViewChild('recaptchaComponent') recaptchaComponent: RecaptchaComponent;

    constructor(
            private _authService: AuthService,
            private _dialogRef: MatDialogRef<AddHoaDialogComponent>,
            private _growler: GrowlerService,
            private _router: Router,
    ) {
    }

    get submitDisabled() {
        return (environment.production && this.captchaToken === null) || this.isRegistering;
    }

    ngOnInit() {
    }

    simpleRegistration(): void {
        if (this.recaptchaComponent && !this.captchaToken) {
            this._growler.error(
                    'Error',
                    'Please re-submit Captcha to determine you are not a bot!',
            );
            return;
        }
        if (
                typeof this.newHoaGroupName === 'undefined' ||
                this.newHoaGroupName === ''
        ) {
            this._growler.error(
                    'Error',
                    'The "HOA Name" field is required.',
            );
            return;
        }

        this.isRegistering = true;

        const groupRequest = {
            groupName: this.newHoaGroupName,
            captcha: this.captchaToken,
        };

        this._authService.registerFromExisting(groupRequest).subscribe(
                data => {
                    if (data.hasOwnProperty('token')) {
                        LegFiJwtService.store(data['token']);
                    }

                    const jwt = LegFiJwtService.read();
                    this.close(jwt.orgId);
                },
                () => {
                    if (this.recaptchaComponent) {
                        this.recaptchaComponent.reset();
                    }
                    this._growler.error(
                            'Error',
                            'We were unable to register your organization.',
                    );
                    this.close();
                },
        );
    }

    resolved($event) {
        this.captchaToken = $event;
    }

    close(orgId: number = null) {
        this._dialogRef.close(orgId);
    }
}
