import { NgModule } from '@angular/core';
import { SharedModule } from '../shared.module';
import { DatepickerComponent } from './datepicker.component';
import { OffClickModule } from '../../../directives/off-click/off-click.module';

@NgModule({
    imports: [
        // Shared
        SharedModule,
        // Dependencies
        OffClickModule,
    ],
    declarations: [DatepickerComponent],
    exports: [DatepickerComponent],
})
export class DatepickerModule
{
}
